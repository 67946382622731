<template>
  <a-card class="no-y-padding" :title="'Trending'">
    <div v-if="!loading && allNoData" class="load-wrapper">
      <NoDataFound></NoDataFound>
    </div>
    <template #extra>
      <div v-if="isMobileScreen && !loading && allNoData" class="category-top-right-action d-flex">
        <div class="overview-menu">
          <a-dropdown
            class="accumulation-menu-wrap"
            :placement="'bottomRight'"
            trigger="click"
            :getPopupContainer="(trigger) => trigger.parentNode"
          >
            <div class="accumulation-menu-button">
              <FeatherIcon type="more-horizontal" :size="16" />
            </div>
            <template #overlay>
              <div class="overview-dropdown">
                <div v-if="selectedDataRepresentation === 'volume'" class="graph-mode">
                  <span class="mode">Graph Mode:</span>
                  <a-checkbox
                    v-model:checked="accumulationMode"
                    class="acc-toggle-mobile"
                    :class="['accumulation-mode', selectedDataRepresentation !== 'volume' ? 'hidden' : '']"
                  >
                    Accumulation Mode
                  </a-checkbox>
                </div>
                <div class="representation-mode">
                  <span class="mode">Data Aspect:</span>
                  <a-menu class="accumulation-menu" @click="setRepresentation">
                    <a-menu-item
                      :key="'volume'"
                      class="accumulation-menu-item-wrapper"
                      :style="
                        selectedDataRepresentation === 'volume'
                          ? selectedCategoryLevelStyle
                          : unselectedCategoryLevelStyle
                      "
                    >
                      <a
                        class="accumulation-menu-item"
                        :style="
                          selectedDataRepresentation === 'volume'
                            ? selectedCategoryLevelStyle
                            : unselectedCategoryLevelStyle
                        "
                      >
                        Volume
                      </a>
                    </a-menu-item>
                    <a-menu-item
                      :key="'sentiment'"
                      class="accumulation-menu-item-wrapper"
                      :style="
                        selectedDataRepresentation === 'sentiment'
                          ? selectedCategoryLevelStyle
                          : unselectedCategoryLevelStyle
                      "
                    >
                      <a
                        class="accumulation-menu-item"
                        :style="
                          selectedDataRepresentation === 'sentiment'
                            ? selectedCategoryLevelStyle
                            : unselectedCategoryLevelStyle
                        "
                      >
                        Sentiment
                      </a>
                    </a-menu-item>
                    <a-sub-menu
                      class="accumulation-menu-item-wrapper accumulation-sub-menu-item-wrapper"
                      :getPopupContainer="(trigger) => trigger.parentNode"
                    >
                      <template #title><span class="accumulation-sub-menu-item">Category</span></template>
                      <a-menu-item
                        v-for="cat in categoryData"
                        :key="cat.level"
                        :style="
                          selectedCategoryLevel === cat.level
                            ? selectedCategoryLevelStyle
                            : unselectedCategoryLevelStyle
                        "
                        class="accumulation-sub-menu"
                        @click="handleMenuClick"
                      >
                        {{ cat.name }}
                      </a-menu-item>
                    </a-sub-menu>
                  </a-menu>
                </div>
              </div>
            </template>
          </a-dropdown>
        </div>
      </div>
    </template>
    <div v-if="!loading && !allNoData" class="card">
      <a-row :gutter="[25, 25]">
        <a-col
          :xxl="{ span: 12, offset: 0 }"
          :xl="{ span: 12, offset: 0 }"
          :lg="{ span: 12, offset: 0 }"
          :md="{ span: 22, offset: 1 }"
          :sm="{ span: 22, offset: 1 }"
          :xs="{ span: 22, offset: 1 }"
        >
          <TrendingStatSummary :value="valueType" :filter="filter" @typeChange="onValueTypeChange" />
        </a-col>
        <a-col
          v-if="!isMobileScreen"
          :xxl="{ span: 12, offset: 0 }"
          :xl="{ span: 12, offset: 0 }"
          :lg="{ span: 12, offset: 0 }"
          :md="{ span: 22, offset: 1 }"
          :sm="{ span: 22, offset: 1 }"
          :xs="{ span: 22, offset: 1 }"
        >
          <div class="data-representation-selector-wrapper">
            <div class="accumulation-wrapper">
              <a-checkbox
                v-model:checked="accumulationMode"
                class="acc-toggle"
                :class="['accumulation-mode', selectedDataRepresentation !== 'volume' ? 'hidden' : '']"
              >
                Accumulation Mode
              </a-checkbox>
              <a-radio-group
                v-model:value="selectedDataRepresentation"
                class="mode-toggle"
                button-style="solid"
                size="default"
              >
                <a-radio-button value="volume"> Volume</a-radio-button>
                <a-radio-button value="sentiment"> Sentiment</a-radio-button>
                <a-dropdown
                  :trigger="['click']"
                  placement="bottomRight"
                  :get-popup-container="(trigger) => trigger.parentNode"
                >
                  <template #overlay>
                    <a-menu id="menu-group" @click="handleMenuClick">
                      <a-menu-item-group title="Select category level">
                        <a-menu-item
                          v-for="cat in categoryData"
                          :key="cat.level"
                          :style="
                            selectedCategoryLevel === cat.level
                              ? selectedCategoryLevelStyle
                              : unselectedCategoryLevelStyle
                          "
                        >
                          {{ cat.name }}
                        </a-menu-item>
                      </a-menu-item-group>
                    </a-menu>
                  </template>
                  <a-radio-button value="category">
                    <span class="flex-align-center">
                      <span>Category</span><FeatherIcon class="ml-1" type="chevron-down" />
                    </span>
                  </a-radio-button>
                </a-dropdown>
              </a-radio-group>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="!loading && seriesData.length > 0">
        <a-col :span="24" :offset="0">
          <TrendingChart class="chart" :chart-type="chartType" :series="seriesData"></TrendingChart>
        </a-col>
      </a-row>
      <a-row v-else-if="!loading && seriesData.length <= 0" class="load-wrapper">
        <NoDataFound></NoDataFound>
      </a-row>
      <div v-if="loading" class="load-wrapper">
        <a-skeleton :loading="loading" active />
      </div>
    </div>
    <div v-if="loading" class="load-wrapper">
      <a-skeleton :loading="loading" active />
    </div>
  </a-card>
</template>

<script>
import TrendingChart from '@/components/Chart/TrendingChart';
import api from '@/services/api';
import { ref, reactive, watchEffect, watch, computed, toRefs } from 'vue';
import VueTypes from 'vue-types';
import TrendingStatSummary from '@/components/Chart/TrendingStatSummary.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';
import { useStore } from 'vuex';

const TrendingHistory = {
  name: 'TrendingHistory',

  components: {
    TrendingChart,
    TrendingStatSummary,
    NoDataFound,
  },

  props: {
    filter: VueTypes.object.def({}),
  },

  setup(props) {
    const { state, dispatch } = useStore();
    let { filter } = toRefs(props);
    const categoryData = computed(() => state.config.categoryData);
    const isMobileScreen = computed(() => state.isMobileScreen);
    let loading = ref(true);
    let error = ref(false);
    let accumulationMode = ref(false);
    let seriesData = ref([]);
    let chartType = ref('line');
    let valueType = ref('engagement');
    let selectedDataRepresentation = ref('volume');
    let selectedCategoryLevel = ref(0);
    let engagementSeries = ref([]);
    let countSeries = ref([]);
    let viewSeries = ref([]);
    const selectValueType = () => {
      const lowerCaseCurrent = valueType.value.toLowerCase();
      if (lowerCaseCurrent === 'engagement') {
        seriesData.value = engagementSeries.value;
      } else if (lowerCaseCurrent === 'mentioned') {
        seriesData.value = countSeries.value;
      } else if (lowerCaseCurrent === 'view') {
        seriesData.value = viewSeries.value;
      } else {
        seriesData.value = [];
      }
    };
    const currentGraphCriteria = ref({});
    const allNoData = computed(() => {
      if (
        engagementSeries.value.length == 0 &&
        countSeries.value.length == 0 &&
        viewSeries.value.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    });
    const drilldownFn = (pointOption, payload = { title: 'data' }) => {
      // console.log(pointOption);

      // const type = selectedDataRepresentation.value;
      const { graphTime } = currentGraphCriteria.value;
      const { x } = pointOption;
      const { time, title } = helper.getTimeOpt(x, graphTime);
      // console.log('CLIKC', type, time, title, payload);
      let sortingField = 'engagement_score';
      if (valueType.value === 'view') {
        sortingField = 'view_count';
      }
      let f = {
        payload: {
          title: `${payload.title} ${title}`,
        },
        criteria: {
          ...filter.value,
          time,
          ...payload.criteria,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: sortingField,
          },
          highlight: {
            enable: true,
          },
        },
      };
      dispatch('message/showMessageModal', f);
    };

    const loadData = async (rawFilter) => {
      loading.value = true;
      error.value = false;
      const { graphCriteria, granularity } = chartHelper.modifyGranularity({}, rawFilter);
      currentGraphCriteria.value = graphCriteria;
      const apiFilter = {
        ...rawFilter,
        ...graphCriteria,
      };
      if (selectedDataRepresentation.value === 'volume') {
        const isAccumulation = accumulationMode.value;
        const qs = reactive({
          accumulation: isAccumulation,
        });
        const result = await api.getHistoryData(apiFilter, qs).catch(() => {
          console.error('History api call error', result);
          error.value = true;
        });
        if (result.message != null && result.message.graphData != null && result.message.graphData.data) {
          const data = result.message.graphData.data;
          const engagementDataList = [];
          const countDataList = [];
          const viewDataList = [];
          let dataCount = {
            engagement: 0,
            count: 0,
            view: 0,
          };
          data.forEach((entry) => {
            let x = entry.x;
            if (entry.y != null) {
              const { engagement = 0, count = 0, view = 0 } = entry.y;
              engagementDataList.push([x, engagement]);
              countDataList.push([x, count]);
              viewDataList.push([x, view]);
              if (engagement > 0) dataCount.engagement += 1;
              if (count > 0) dataCount.count += 1;
              if (view > 0) dataCount.view += 1;
            }
          });

          const color = '#5e66e9';
          if (dataCount.engagement === 0) {
            // all zero
            engagementSeries.value = [];
          } else {
            engagementSeries.value = [
              {
                name: 'Engagement',
                color: color,
                data: engagementDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, { title: 'Messages' }),
                  },
                },
                custom: {
                  granularity,
                },
              },
            ];
          }
          if (dataCount.count === 0) {
            // all zero
            countSeries.value = [];
          } else {
            countSeries.value = [
              {
                name: 'Mentioned',
                color: color,
                data: countDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, { title: 'Messages' }),
                  },
                },
                custom: {
                  granularity,
                },
              },
            ];
          }
          if (dataCount.view === 0) {
            viewSeries.value = [];
          } else {
            viewSeries.value = [
              {
                name: 'View',
                color: color,
                data: viewDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, { title: 'Messages' }),
                  },
                },
                custom: {
                  granularity,
                },
              },
            ];
          }
          chartType.value = 'line';
          selectValueType();
        }
      }
      if (selectedDataRepresentation.value === 'sentiment') {
        const qs = reactive({});
        const result = await api.getSentimentHistoryGraph(apiFilter, qs).catch(() => {
            console.error('History api call error', result);
            error.value = true;
          });
        let newEngagementSeries = [];
        let newCountSeries = [];
        let newViewSeries = [];
        let dataCount = {
          yEngagement: 0,
          yCount: 0,
          yView: 0,
        };
        if (result.message != null && result.message.graphData != null) {
          const graphData = result.message.graphData;
          let count = 0;
          for (const [sentimentType, sentimentData] of Object.entries(graphData)) {
            const data = sentimentData.data;
            if (data != null) {
              const engagementDataList = [];
              const countDataList = [];
              const viewDataList = [];
              data.forEach((entry) => {
                let x = entry.x;
                if (entry.y != null) {
                  let yEngagement = entry.y.engagement || 0;
                  engagementDataList.push([x, yEngagement]);
                  let yCount = entry.y.count || 0;
                  countDataList.push([x, yCount]);
                  let yView = entry.y.view || 0;
                  viewDataList.push([x, yView]);

                  if (yEngagement > 0) dataCount.yEngagement += 1;
                  if (yCount > 0) dataCount.yCount += 1;
                  if (yView > 0) dataCount.yView += 1;
                }
              });
              let name = helper.capitalize(sentimentType);
              const color = chartHelper.getColor(sentimentType.toLowerCase(), count);
              count++;
              const pl = { criteria: { sentiment: [sentimentType] }, title: `${name} sentiment` };
              if (name) {
                if (dataCount.yEngagement === 0) {
                  newEngagementSeries = [];
                } else {
                  newEngagementSeries.push({
                    name: name,
                    color: color,
                    data: engagementDataList,
                    point: {
                      events: {
                        click: (e) => drilldownFn(e.point.options, pl),
                      },
                    },
                    custom: {
                      granularity,
                    },
                  });
                }

                if (dataCount.yCount === 0) {
                  newCountSeries = [];
                } else {
                  newCountSeries.push({
                    name: name,
                    color: color,
                    data: countDataList,
                    point: {
                      events: {
                        click: (e) => drilldownFn(e.point.options, pl),
                      },
                    },
                    custom: {
                      granularity,
                    },
                  });
                }

                if (dataCount.yView === 0) {
                  newViewSeries = [];
                } else {
                  newViewSeries.push({
                    name: name,
                    color: color,
                    data: viewDataList,
                    point: {
                      events: {
                        click: (e) => drilldownFn(e.point.options, pl),
                      },
                    },
                    custom: {
                      granularity,
                    },
                  });
                }
              }
            }
          }
        }
        chartType.value = 'column';
        engagementSeries.value = newEngagementSeries;
        countSeries.value = newCountSeries;
        viewSeries.value = newViewSeries;
        selectValueType();
      }
      if (selectedDataRepresentation.value === 'category') {
        let categoryFilterData = state.config.categoryData.find((it) => it.level === selectedCategoryLevel.value);
        let visibleCategory = new Set();
        if (categoryFilterData) {
          visibleCategory = new Set(categoryFilterData.categories.filter((it) => it.visible).map((it) => it.category));
        }
        const qs = {
          level: selectedCategoryLevel.value,
        };
        const result = await api.getCategoryHistoryGraph(apiFilter, qs).catch(() => {
            console.error('History api call error', result);
            error.value = true;
          });
        const newEngagementSeries = [];
        const newCountSeries = [];
        const newViewSeries = [];
        if (result.message != null && result.message.graphData != null) {
          const graphData = result.message.graphData;
          let count = 0;
          for (const [category, sentimentData] of Object.entries(graphData)) {
            if (visibleCategory.has(category)) {
              const data = sentimentData.data;
              if (data != null) {
                const engagementDataList = [];
                const countDataList = [];
                const viewDataList = [];
                data.forEach((entry) => {
                  let x = entry.x;
                  if (entry.y != null) {
                    let yEngagement = entry.y.engagement || 0;
                    engagementDataList.push([x, yEngagement]);
                    let yCount = entry.y.count || 0;
                    countDataList.push([x, yCount]);
                    let yView = entry.y.view || 0;
                    viewDataList.push([x, yView]);
                  }
                });
                let name = helper.capitalize(category);
                // const color = chartHelper.getColor(category.toLowerCase(), count);
                const color = helper.getColorByCategory(selectedCategoryLevel.value, category.toLowerCase(), count);
                const pl = {
                  title: `${name} category`,
                  criteria: {},
                };
                if (selectedCategoryLevel.value === 0) {
                  pl.criteria.category = [category];
                } else {
                  pl.criteria.subCategory = [
                    {
                      level: selectedCategoryLevel.value,
                      category: [category],
                    },
                  ];
                }
                count++;
                if (name) {
                  newEngagementSeries.push({
                    name: name,
                    color: color,
                    data: engagementDataList,
                    point: {
                      events: {
                        click: (e) => drilldownFn(e.point.options, pl),
                      },
                    },
                    custom: {
                      granularity,
                    },
                  });
                  newCountSeries.push({
                    name: name,
                    color: color,
                    data: countDataList,
                    point: {
                      events: {
                        click: (e) => drilldownFn(e.point.options, pl),
                      },
                    },
                    custom: {
                      granularity,
                    },
                  });
                  newViewSeries.push({
                    name: name,
                    color: color,
                    data: viewDataList,
                    point: {
                      events: {
                        click: (e) => drilldownFn(e.point.options, pl),
                      },
                    },
                    custom: {
                      granularity,
                    },
                  });
                }
              }
            }
          }
        }
        chartType.value = 'line';
        engagementSeries.value = newEngagementSeries;
        countSeries.value = newCountSeries;
        viewSeries.value = newViewSeries;
        selectValueType();
      }
      loading.value = false;
    };

    watchEffect(() => {
      loading.value = true;
      error.value = false;
      loadData(filter.value).finally(() => {
        loading.value = false;
      });
    });

    watch(selectedDataRepresentation, (current, previous) => {
      if (current !== previous) {
        if (current !== 'category') {
          selectedCategoryLevel.value = null;
        }
      }
    });

    watch(valueType, (current, previous) => {
      if (current !== previous) {
        const lowerCaseCurrent = current.toLowerCase();
        if (lowerCaseCurrent === 'engagement') {
          seriesData.value = engagementSeries.value;
        } else if (lowerCaseCurrent === 'mentioned') {
          seriesData.value = countSeries.value;
        } else if (lowerCaseCurrent === 'view') {
          seriesData.value = viewSeries.value;
        } else {
          seriesData.value = [];
        }
      }
    });

    // onMounted(async () => {
    //   await loadData(filter.value);
    // });

    const handleMenuClick = (e) => {
      // if (selectedCategoryLevel.value !== e.key) {
      selectedCategoryLevel.value = e.key;
      selectedDataRepresentation.value = 'category';
      //load new graph data here
      // console.log('load data');
      // }
    };

    const selectedCategoryLevelStyle = {
      'background-color': '#f2f7fe',
      color: '#337df6',
    };

    const unselectedCategoryLevelStyle = {
      color: '#868EAE',
    };

    const onValueTypeChange = (e) => {
      valueType.value = e;
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const setRepresentation = (value) => {
      selectedDataRepresentation.value = value.key;
    };

    return {
      handleMenuClick,
      filter,
      selectedCategoryLevel,
      selectedDataRepresentation,
      selectedCategoryLevelStyle,
      unselectedCategoryLevelStyle,
      seriesData,
      chartType,
      valueType,
      onValueTypeChange,
      accumulationMode,
      loading,
      categoryData,
      isMobileScreen,
      clickMenu,
      setRepresentation,
      allNoData
    };
  },
};

export default TrendingHistory;
</script>

<style lang="scss" scoped>
.overview-dropdown {
  min-width: 191px;
  .graph-mode {
    padding: 19px 12px 14px;
    border-bottom: 1px solid #f1f2f6;
    .mode {
      display: block;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;
      color: #9299b8;
      margin-bottom: 10px;
    }
    .acc-toggle-mobile {
      margin-top: 0;
      justify-content: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #9299b8;
    }
  }
  .representation-mode {
    padding: 12px 12px 21px;
    .mode {
      display: block;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;
      color: #9299b8;
      margin-bottom: 10px;
    }
    .accumulation-menu {
      display: block;
      .accumulation-menu-item-wrapper {
        .accumulation-menu-item {
          width: 100%;
          height: 34px;
          padding: 6px 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #5a5f7d;
        }
        &.accumulation-sub-menu-item-wrapper {
          .accumulation-sub-menu-item {
            width: 100%;
            height: 34px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #5a5f7d;
          }
        }
      }
    }
  }
}
.trending-history-card .ant-card-body {
  padding: 0 25px !important;
}
.card {
  min-height: 490px;
}

.data-representation-selector-wrapper {
  display: flex;
  justify-content: flex-end;
}

.chart {
  margin-top: 25px;
}

.accumulation-wrapper {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 16px;
  column-gap: 16px;
  .acc-toggle {
    margin-top: 0;
    justify-content: center;
  }
  @media only screen and (max-width: 1330px) and (min-width: 992px) {
    .acc-toggle {
      order: 2;
      margin-right: 48px;
    }
    .mode-toggle {
      order: 1;
    }
  }
}

// @media only screen and (max-width: 992px) {
//   .data-representation-selector-wrapper {
//     justify-content: flex-start;
//   }
//   .acc-toggle {
//     order: 1;
//   }
//   .mode-toggle {
//     order: 2;
//   }
// }

.hidden {
  display: none;
}

.load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 385px;
}

@media only screen and (max-width: 575px) {
  .accumulation-wrapper {
    justify-content: unset;
    padding-top: unset;
  }
  .mode-toggle {
    display: flex;
  }
}
</style>
<style scoped>
:deep(.ant-menu-item) {
  display: block;
  width: auto;
  height: 34px;
  margin: 0;
  padding: 0;
}
:deep(.ant-menu-vertical .ant-menu-item:not(:last-child)) {
  margin: 0;
}
:deep(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background: rgba(51, 113, 255, 0.05);
}
:deep(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content a.accumulation-menu-item) {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #3371ff;
}
:deep(.ant-menu-vertical .ant-menu-item::after) {
  border: none;
}
:deep(.accumulation-sub-menu-item-wrapper) {
  display: block;
  width: auto;
  height: 34px;
  margin: 0;
  padding: 0;
}
:deep(.ant-menu-submenu-title) {
  display: block;
  width: auto;
  height: 34px;
  margin: 0;
  padding: 6px 12px;
}
:deep(.ant-menu-submenu-title .ant-menu-title-content) {
  height: 34px;
  line-height: 34px;
}
:deep(.ant-menu-submenu-arrow) {
  color: #adb4d2;
}
:deep(.ant-menu-item-selected a) {
  color: #337df6 !important;
}
</style>
<style>
.ant-menu-submenu-popup {
  left: auto !important;
  right: 20px;
  top: 40px !important;
}
.ant-menu-vertical.ant-menu-sub {
  min-width: 100px;
}
.ant-menu-item-selected.accumulation-sub-menu .ant-menu-title-content {
  color: #337df6 !important;
}
</style>
